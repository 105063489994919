
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        



































































.aw-mood-slider__heading__introduction {
  @include mq(l) {
    padding-right: col(5);
  }
}

.aw-mood-slider__slider {
  ::v-deep .flexible-slider-inner {
    border-top: none;
  }

  ::v-deep .flexible-slider-container {
    height: 40rem;
    margin-top: 0;
  }

  /* stylelint-disable-next-line selector-max-combinators, selector-max-compound-selectors */
  ::v-deep .aw-mood__picture,
  ::v-deep .aw-mood__picture img {
    height: 100%;
  }

  /* stylelint-disable-next-line selector-max-combinators, selector-max-compound-selectors */
  ::v-deep .aw-mood__picture img {
    object-fit: cover;
    object-position: center;
  }

  ::v-deep .flexible-slider__controls {
    margin-top: $spacing;
  }

  @include mq($until: l) {
    ::v-deep .flexible-slider__item {
      width: 32rem;
    }
  }

  @include mq(l) {
    ::v-deep .flexible-slider-container {
      height: 56rem;
    }

    ::v-deep .flexible-slider__controls {
      margin-top: $spacing * 1.5;
    }
  }
}

.aw-mood-slider__slider__item {
  height: 100%;

  @include mq(l) {
    width: 36rem;
  }
}
